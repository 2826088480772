<template>
  <div class="your-roof">
    <div class="your-roof-header">
      <h1 class="title">{{$t('Your Roof')}}</h1>
      <RFButton class="mb-2" size="small" label="Update" background="#24425B" @on-click="getReservationList()" v-if="!loading"/>
    </div>
    <div class="choose-reservation" v-if="reservationList.length && !loading">{{$t('Choose the booking request you prefer in order to visualize all the relative details',{bookingRequest : $t('Booking request')})}}</div>
    <template v-if="!loading">
      <div class="reservations mt-4" v-if="reservationList.length">
        <Reservation class="m-2 mb-4 ml-lg-4" :class="{ 'active': index === 0 }" v-for="(reservation, index) in reservationList" :key="index" :reservation="reservation" :index="index"/>
      </div>
      <div class="reservations-empty" v-else>
        <p class="text-uppercase m-0">{{$t('There are no reservations.')}}</p>
        <p class="rf-dark-gray-color m-0">{{$t('In order to see your reservations, upload your documents please.')}}</p>
      </div>
    </template>
    <template v-else>
      <div class="loading">
        <RFLoader :size="30" color="#24425B"/>
        <div class="uploading">{{$t('Uploading...')}}</div>
      </div>
    </template>
    <ContractModal @accept="updateReservation"/>
  </div>
</template>

<script>
import Reservation from '@/components/reservations/Reservation'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import ContractModal from '@/components/modals/ContractModal'

import { userService } from '@/services'

export default {
  name: 'ProfileYourRoof',
  components: {
    Reservation,
    RFButton,
    RFLoader,
    ContractModal,
  },
  data() {
    return {
      reservationList: [],
      loading: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.userStore.user
    }
  },
  async beforeMount() {
    await this.getReservationList()
  },
  methods: {
    async getReservationList() {
      this.loading = true
      this.reservationList = await userService.fetchReservationList(this.user.id)
      this.$store.commit('userStore/updateReservationList', this.reservationList)
      this.loading = false
    },
    updateReservation(updatedReservation) {
      const index = this.reservationList.findIndex(reservation => reservation.id === updatedReservation.id)
      this.reservationList[index] = updatedReservation
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.your-roof {
  position: relative;
  padding-top: 30px;
  min-height: 80vh;
  margin-bottom: 60px;
  z-index: 2;

  .your-roof-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: .5px solid $dark-gray;

    .title {
      @include font-style($montserrat, 'medium', $font-20);
      color: $dark-gray;
      text-transform: uppercase;
      padding-bottom: 10px;
      margin-left: 0;
    }
  }
  .choose-reservation {
    @include font-style($montserrat, 'medium', $font-14);
    color: $dark-blue;
  }
  .reservations {
    overflow-y: auto;
  }
  .reservations-empty {
    @include font-style($montserrat, 'semibold', $font-18);
    color: $dark-blue;
    padding-top: 30px;
  }
  .loading {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .uploading {
      @include font-style($montserrat, 'medium', $font-16);
      color: $dark-blue;
      margin-left: 20px;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .your-roof {
    padding-top: 0;

    .your-roof-header {
      margin: 0 40px;
    }
    .title,
    .choose-reservation,
    .reservations-empty {
      margin: 0 40px;
    }
    .choose-reservation {
      margin-top: 20px;
    }
    .loading {
      padding: 0 40px;
    }
  }
}
</style>

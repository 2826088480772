<template>
  <RFModal v-if="reservation" :title="isApartment ? accommodation.name : `${reservation.buildingName} - ${reservation.roomType.name}` | replaceUnderscore"
   :modalId="modalId" :hide-footer="reservation && reservation.contractAccepted"
   ok-label="Accept" @send="acceptContract">
    <div class="note">{{$t('This is a template of the contract that will be signed during check-in. Please click on Accept to confirm viewing')}}</div>
    <object class="contract" v-if="contractPath" :data="contractPath" type="application/pdf"/>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import RFButton from '@/components/forms/RFButton'
import { documentService } from '@/services'

import { mapState } from 'vuex'
import { docTypes } from '@/enums'
import { docExtensions } from "../../enums/document-extensions"
import { getAsset } from '@/helpers'

export default {
  name: 'ContractModal',
  components: {
    RFButton,
    RFModal,
  },
  data() {
    return {
      modalId: 'contract-modal',
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.contractStore.reservation,
      user: state => state.userStore.user,
    }),
    contract() {
      return this.reservation ? this.getContract() : null
    },
    accommodation() {
      return this.reservation ? this.reservation.apartment || this.reservation.room : null
    },
    isApartment() {
      return this.reservation ? !!this.reservation.apartment : null
    },
    contractPath() {
      return this.contract ? getAsset(this.contract.path) : null
    }
  },
  methods: {
    getContract() {
      const index = this.reservation.documents.findIndex(document => document.docType === docTypes.CONTRACT && document.format === docExtensions.PDF)
      return this.reservation.documents[index]
    },
    resetContract() {
      this.$store.commit('contractStore/setReservation', null)
      this.$root.$emit('bv::hide::modal', this.modalId)
    },
    async acceptContract() {
      const reservation = await documentService.acceptContract(this.reservation.id, this.user.id)
      this.$emit('accept', reservation)
      this.$root.$emit('bv::hide::modal', this.modalId)
    }
  }
}
</script>

<style lang="scss" scoped>
.contract {
  width: 100%;
  height: 70vh;
}
.note {
  @include font-style($montserrat, 'medium', $font-18);
  color: $dark-blue;
  margin-bottom: 10px;
}
</style>

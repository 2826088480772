<template>
  <div class="reservation" ref="reservation" :style="reservationStyle">
    <b-row  @click="isActive ? openReservationDetail() : scrollToReservation()">
      <b-col cols="12" md="6" class="mb-3 mb-md-0" :class="{
        'rf-green-color': !isRoom,
        'rf-orange-color': isRoom,
      }">
        <template v-if="isRoomType">
          <div class="accommodation-type text-uppercase">{{reservation.roomType.title}}</div>
          <div class="accommodation-type text-uppercase subtitle mb-3">{{reservation.roomType.subtitle}}</div>
        </template>
        <template v-else>
          <div class="accommodation-type text-uppercase" v-if="accommodation.type.title">{{accommodation.type.title}}</div>
          <div class="accommodation-type text-uppercase subtitle mb-3" v-if="accommodation.type.subtitle">{{accommodation.type.subtitle}}</div>
        </template>
        <div class="guests">
          <FontAwesomeIcon :icon="['fas', 'angle-right']" class="mr-2"/> {{$t('Guest')}}
          <FontAwesomeIcon class="ml-2" color="#000" :icon="['fas', 'user']" v-for="(guest, index) in numOfGuests" :key="index"/>
        </div>
        <div class="period">
          <FontAwesomeIcon :icon="['fas', 'angle-right']" class="mr-2"/> {{$t('Period')}}
          <span class="period-label">
            {{reservation.startDate | extractMonthLocaleFromDate}} {{reservation.startDate | extractYearFromDate}} - {{reservation.expiringDate | extractMonthLocaleFromDate}} {{reservation.expiringDate | extractYearFromDate}}
          </span>
        </div>
      </b-col>
      <b-col v-if="reservation.room || reservation.apartment">RESERVATION
        <VueperSlides :slide-ratio="0.7" :arrows="true" :bullets="false" infinite  :touchable="false">
          <VueperSlide v-for="(image, index) in accommodation.images" :key="index">
            <template v-bind:class="[ image.pathname.split('.')[1]==='mp4' ? 'video-container' : '']" #content>
              <div v-if="image.pathname.split('.')[1]!=='mp4'" class="vueperslide__content-wrapper">
                <b-img :src="image.pathname || null | getAsset" class="sliderCursor" fluid  @click="showModal(index)"  />
              </div>
              <video v-if="image.pathname.split('.')[1]==='mp4'" autoplay muted loop playsinline class="sliderCursor" @click="showModal(index)">
                <source :src="image.pathname | getAsset" type="video/mp4">
              </video>
            </template>
          </VueperSlide>
        </VueperSlides>
      </b-col>
    </b-row>
    <b-row class="border-top-gray justify-content-between" v-if="!isRoomType"
           @click="isActive ? openReservationDetail() : scrollToReservation()">
      <b-col cols="12" class="prices">
        <b-col cols="12" md="5">
          <div class="price" v-if="reservation.monthlyAmount">{{$t('Price')}} <FontAwesomeIcon v-if="!categoriesInfo['Price'].visible" class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-down']" @click="openCategoryDescription('Price')"/> <FontAwesomeIcon v-if="categoriesInfo['Price'].visible" class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-up']" @click="closeCategoryDescription('Price')"/>
            <div class="amount">{{reservation.monthlyAmount}} &euro;</div>
          </div>
        </b-col>
        <b-col cols="12">
          <div v-if="categoriesInfo['Price'].visible">
            <p class="description" v-for="descriptionParagraph in categoriesInfo['Price'].description" :key="descriptionParagraph">
              {{$t(descriptionParagraph)}}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="5">
          <div class="deposit" v-if="reservation.depositAmount">{{$t('Deposit')}} <FontAwesomeIcon v-if="!categoriesInfo['Deposit'].visible" class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-down']" @click="openCategoryDescription('Deposit')"/> <FontAwesomeIcon v-if="categoriesInfo['Deposit'].visible" class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-up']" @click="closeCategoryDescription('Deposit')"/>
            <div class="amount">{{reservation.depositAmount}} &euro;</div>
          </div>
        </b-col>
        <b-col cols="12">
          <div v-if="categoriesInfo['Deposit'].visible">
            <p  class="description" v-for="descriptionParagraph in categoriesInfo['Deposit'].description" :key="descriptionParagraph">{{$t(descriptionParagraph)}}</p>
          </div>
        </b-col>
        <b-col cols="12" md="5">
          <div class="service-fees">{{$t('Service Fees')}} <FontAwesomeIcon v-if="!categoriesInfo['Service Fees'].visible" class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-down']" @click="openCategoryDescription('Service Fees')"/> <FontAwesomeIcon v-if="categoriesInfo['Service Fees'].visible" class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-up']" @click="closeCategoryDescription('Service Fees')"/>
            <div class="amount">{{reservation.serviceFees}} &euro;</div>
          </div>
        </b-col>
        <b-col cols="12">
          <div v-if="categoriesInfo['Service Fees'].visible">
            <p class="description" v-for="descriptionParagraph in categoriesInfo['Service Fees'].description" :key="descriptionParagraph">{{$t(descriptionParagraph)}}</p>
          </div>
        </b-col>
      </b-col>
      <b-col cols="auto" class="mt-3 mt-md-0">
        <div class="total">
          <div class="total-label">{{$t('Total')}}</div>
          <div class="total-amount">&euro; {{`${reservation.monthlyAmount} /`}}
            <span class="per-month">{{`${$t('month')}`}}</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-between mt-5" v-if="(isAccepted || isActive) || (reservation.guests.length >= 1) || (isRoomType && (isCompleted || isAccepted)) || isAccepted">
      <b-col cols="auto" class="mb-3 mb-md-0">
        <RFButton label="View Contract" :background="!isRoom ? '#67AEA6' : '#E26F5A'" color="#fff" @on-click="selectContract" v-if="isAccepted || isActive"/>
      </b-col>
      <b-col cols="auto" v-if="reservation.guests.length >=1">
        <router-link :to="{ name: 'your-roof-guests', params: { id: reservation.id } }">
          <RFButton label="Guests" :background="!isRoom ? '#67AEA6' : '#E26F5A'" color="#fff"/>
        </router-link>
      </b-col>
      <b-col cols="auto">
        <a v-if="isAccepted && reservation.contractAccepted" @click="selectReservation" :href="`/profile/your-roof/${reservation.id}/payment`">
          <RFButton label="Payment" :background="!isRoom ? '#67AEA6' : '#E26F5A'" color="#fff"/>
        </a>
      </b-col>
      <b-col cols="auto" v-if="isActive && isCreditCard">
        <a :href="`/profile/your-roof/${reservation.id}/change-card`" @click="selectReservation">
          <RFButton label="Change card" :background="!isRoom ? '#67AEA6' : '#E26F5A'" color="#fff"/>
        </a>
      </b-col>
    </b-row>
    <b-row class="justify-content-between mt-3" v-if="(isAccepted || isActive) || (reservation.guests.length >= 1) || (isRoomType && (isCompleted || isAccepted)) || isAccepted || (isExpired && isLastMonth)">
      <b-col cols="auto" v-if="(isActive && reservation.availableToPay && !rentWithBankTransfer) || (isExpired && isLastMonth && reservation.availableToPay && !rentWithBankTransfer)">
        <a :href="payMonthlyRentUrl" @click="selectReservation">
          <RFButton label="Pay my monthly rent" :background="!isRoom ? '#67AEA6' : '#E26F5A'" color="#fff"/>
        </a>
      </b-col>
    </b-row>
    <CarouselModal :index="index"/>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFCheckbox from '@/components/forms/RFCheckbox'
import { reservationStatus, recipientTypes } from '@/enums'
import CarouselModal from '../modals/CarouselModal.vue'

export default {
  name: 'Reservation',
  components: {
    RFButton,
    CarouselModal,
    RFCheckbox,
  },
  props: {
    reservation: Object,
    index: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      reservationStatus,
      recipientTypes,

      viewContract: false,

      offset: null,
      height: null,
      spaceOccuped: null,
      categoriesInfo:{
        "Price":{
          description: [
            "Price amount description",
            "This price does not include the costs of electricity and gas which vary upon consumption and will be communicated once the bills are issued by the specific providers",
            "This amount is due within the first 5 working days of the month via credit card or bank transfer"
          ],
          visible: false
        },
        "Deposit":{
          description : [
            "The amount relative to the security deposit shall be paid in advance. This amount shall be restituted once you leave the apartment and we have checked that it is  in the same conditions as initially delivered"
          ],
          visible: false
        },
        "Service Fees":{
          description : [
          ],
          visible: false
        },
        "Total":{
          description : [
            "The security deposit and service fee need to be paid in order to finalize the booking. Rent will be paid once you enter the unit!"
          ],
          visible: false
        }
      },
      payMonthlyRentUrl: null,
      serviceFeesDescription: null,
    }
  },
  computed: {
    reservationStyle() {
      return {
        '--color': this.reservation.apartment ? '#67AEA6' : '#E26F5A',
      }
    },
    accommodation() {
      return this.reservation.apartment || (this.reservation.room || this.reservation)
    },
    isRoom() {
      return this.reservation.room || this.reservation.roomType
    },
    isRoomType() {
      return !this.reservation.room && !this.reservation.apartment
    },
    status() {
      return this.reservation.status
    },
    numOfGuests() {
      return this.reservation.guests.length + 1
    },
    isCompleted() {
      return this.status === reservationStatus.COMPLETED
    },
    isAccepted() {
      return this.status === reservationStatus.ACCEPTED
    },
    isActive() {
      return this.status === reservationStatus.ACTIVE
    },
    isExpired(){
      return this.status === reservationStatus.EXPIRED
    },
    isLastMonth(){
      return new Date(new Date(this.reservation.expiringDate).toLocaleString('en-US',{ timeZone: 'UTC' })).getMonth() === new Date().getMonth()
    },
    isCreditCard() {
      return this.reservation.cardDetails != null
    },
    rentWithBankTransfer(){
      return (this.reservation.apartment && this.reservation.apartment.bankTransferMonthlyPayments) || (this.reservation.room && this.reservation.room.bankTransferMonthlyPayments)
    },
    hasCreditCardManualPayments(){
      return this.reservation.creditCardManualPayments
    },
  },
  beforeMount() {
    if(this.hasCreditCardManualPayments){
      this.payMonthlyRentUrl = `/profile/your-roof/${this.reservation.id}/pay-monthly-rent-options`
    }
    else{
      this.payMonthlyRentUrl = `/profile/your-roof/${this.reservation.id}/pay-monthly-rent`
    }

    if(this.isRoom){
      this.serviceFeesDescription = "There is a one-time service fee to be paid, which includes support and assistance throughout the entire stay, maintenance costs (so if there is any problem in the house, we fix it for you!). This amount considers VAT charge equal to 22%"
    }
    else{
      this.serviceFeesDescription = "There is a one-time service fee to be paid equal to one month rent, which includes contract registration costs, support during the entire stay, and monthly maintenance check ups which will be performed in the unit"
    }
    this.categoriesInfo['Service Fees'].description.push(this.serviceFeesDescription)
  },
  mounted() {
    if (this.$refs.reservation) {
      this.offset = this.$refs.reservation.getBoundingClientRect().top
      this.height = this.$refs.reservation.clientHeight
      this.spaceOccuped = this.offset + this.height
      const initialPosition = window.pageYOffset + 250
      const offset = 200
      let scroll = window.scrollY + offset

      if (initialPosition >= this.offset && initialPosition <= this.spaceOccuped) {
        this.$refs.reservation.classList.add('active')
      } else {
        this.$refs.reservation.classList.remove('active')
      }

      window.addEventListener('scroll', () => {
        if (this.$refs.reservation) {
          scroll = window.scrollY + offset

          if (scroll === offset) {
            scroll = initialPosition
          }

          if (scroll >= this.offset && scroll <= this.spaceOccuped) {
            this.$refs.reservation.classList.add('active')
          } else {
            this.$refs.reservation.classList.remove('active')
          }
        }
      })
    }
  },
  methods: {
    openCategoryDescription(category){
      for (const cat in this.categoriesInfo) {
        if(cat===category){
          this.categoriesInfo[cat].visible = true
        }
        else{
          this.categoriesInfo[cat].visible = false
        }
      }
    },
    closeCategoryDescription(category){
      this.categoriesInfo[category].visible = false
    },
    showModal(index) {
      this.$store.commit('carouselStore/setIndexSlide', index)
      this.$store.commit('carouselStore/setImagesList', this.accommodation.images)
      let modalId = 'carousel-modal'
      if(this.index!==-1){
        modalId += `-${this.index}`
      }
      this.$root.$emit('bv::show::modal', modalId)
    },
    scrollToReservation() {
      if (this.$refs.reservation && !this.$refs.reservation.classList.contains('active')) {
        window.scroll({
          top: this.offset - 150,
          behavior: 'smooth',
        })

        this.$refs.reservation.classList.add('active')
      }
    },
    openReservationDetail() {
      this.selectReservation()
      this.$router.push({ name: 'your-roof-detail', params: { id: this.reservation.id } })
    },
    selectReservation() {
      this.$store.commit('profileStore/setReservation', this.reservation)
    },
    selectContract() {
      this.$store.commit('contractStore/setReservation', this.reservation)
      this.$root.$emit('bv::show::modal', 'contract-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation {
  padding: 20px;
  position: relative;
  border-left: 20px solid transparent;
  z-index: 1;
  transition: all .3s;

  .video-wrapper {
    /* Will not allow the video to overflow the
    container */
    overflow: hidden;

    /* Centering the container's content vertically
    and horizontally */
    //text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  video {
    /** Simulationg background-size: cover */
    object-fit: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }

  .cursor-pointer {
    cursor: pointer;
  }
  .description-icon{
    color: var(--color);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba($white, .4);
  }
  &.active {
    border-left: 20px solid var(--color);
    box-shadow: 5px 4px 10px 2px $gray;

    &:before {
      background-color: transparent;
    }
  }
  &:hover {
    cursor: pointer;
    border-left: 20px solid var(--color);
    box-shadow: 5px 4px 10px 2px $gray;

    &:before {
      z-index: 0;
    }
  }
  .accommodation-type {
    @include font-style($montserrat-bold, 'bold', $font-26);

    &.subtitle {
      @include font-style($montserrat-light, 'light', $font-26);
    }
  }
  .accommodation {
    @include font-style($montserrat, 'medium', $font-26);
  }
  .period {
    .period-label {
      color: $black;
    }
  }
  .border-top-gray {
    border-top: 1px solid $dark-gray;
    margin-top: 30px;
    padding-top: 20px;

    .prices {
      padding-left: 0px;
      padding-bottom: 10px;
      .price,
      .deposit,
      .service-fees {
        @include font-style($montserrat, 'medium', $font-14);
        color: $dark-blue;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px 0;

        .amount {
          font-weight: $bold;
        }
      }
    }
    .total {
      line-height: 20px;

      .total-label {
        @include font-style($montserrat, 'medium', $font-16);
        color: $dark-blue;
        text-transform: uppercase;
      }
      .total-amount {
        @include font-style($montserrat-bold, 'bold', $font-22);
        color: $dark-blue;

        .per-month {
          @include font-style($montserrat, 'medium', $font-14);
        }
      }
    }
  }
  .description {
    @include font-style($montserrat-light, 'light', $font-12);
    color: $dark-blue;
    margin-top: 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid $dark-gray;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .reservation {
    padding: 40px;
  }
}
</style>
